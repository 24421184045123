@tailwind base;
@tailwind components;
@tailwind utilities;




.tableArea {display: block; width: 100%; height: auto; background-color: #f7f7f7; box-shadow: 0 0 10px rgba(0,0,0,0.1);}
.tableArea table {width: 100px; height: auto; background-color: #fff;}
.tableArea table tr {border: 1px solid #aaa;}
.tableArea table tr:first-child {color: #000; background-color: rgba(41,174,0,0.5);}
.tableArea table tr th {padding: 3px; font-size: 9px; color: #000; background-color: rgba(41,174,0,0.5); border: 1px solid rgba(41,174,0,1); box-sizing: border-box; text-align: center; line-height: normal;}
.tableArea table tr td {padding: 3px; font-size: 9px; color: #000; border: 1px solid rgba(41,174,0,1); box-sizing: border-box; text-align: center; line-height: normal;}

.tf-tree .tf-nc {
    padding: 0!important;
    border: none!important;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
/* 
@media (max-width: 768px) {
    body.char {
        width: 220%;
        overflow-x: scroll;
    }
}


@media (max-width: 600px) {
    body.char {
        width: 280%;
    }
}

@media (max-width: 480px) {
    body.char {
        width: 320%;
    }
}

@media (max-width: 360px) {
    body.char {
        width: 450%;
    }
}

@media (max-width: 320px) {
    body.char {
        width: 500%;
    }
} */